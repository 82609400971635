import React, { useState } from 'react';
import axios from 'axios';
import { baseURL } from './Global/api';
import { Spinner } from './Loader';
import { toast } from 'react-toastify';
import { ImCancelCircle } from 'react-icons/im';

const AddUserModal = ({ onClose, LoadData }) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [phone_number, setPhoneNumber] = useState('');
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [password, setPassword] = useState('');

  const handleButtonDisable = () => {
    return !email || !phone_number || !first_name || !last_name || !password;
  };

  const handleAddUser = async (e) => {
    e.preventDefault();
    let token = '';
    if (localStorage.getItem('userToken')) {
      token = JSON.parse(localStorage.getItem('userToken'));
      setLoading(true);
      const formData = {
        email: email,
        phone_number: phone_number,
        first_name: first_name,
        last_name: last_name,
        password: password,
      };
      try {
        await axios.post(`${baseURL}agent/users`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        onClose();
        LoadData();
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.message);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div
      className={`fixed inset-0 flex items-center m-[10px] lg:m-0 justify-center z-50`}
    >
      <div className='fixed inset-0 bg-[#000] opacity-50'></div>
      <div className='relative bg-[#fff] rounded-lg w-[650px] flex flex-col gap-6'>
        <span className='ml-auto px-6 cursor-pointer py-4' onClick={onClose}>
          <ImCancelCircle className='w-6 h-6 text-[#D22421]' />
        </span>

        <div className=' p-[20px]'>
          <form onSubmit={handleAddUser}>
            <div className='flex flex-col mb-6'>
              <label
                for='firstname'
                className='mb-1 text-[16px] tracking-wide text-gray-600'
              >
                First Name
              </label>
              <div className='relative'>
                <div className='inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400'></div>

                <input
                  type='text'
                  name='first_name'
                  value={first_name}
                  required
                  onChange={(e) => setFirstName(e.target.value)}
                  className='text-sm sm:text-base placeholder-gray-400 px-[14px] py-[12px] rounded-lg border-1 border-[#E8E8E8] w-full  focus:outline-none focus:border-primary focus:border-1'
                  placeholder='First Name '
                />
              </div>
            </div>

            <div className='flex flex-col mb-6'>
              <label
                for='lasttname'
                className='mb-1 text-[16px] tracking-wide text-gray-600'
              >
                Last Name
              </label>
              <div className='relative'>
                <div className='inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400'></div>

                <input
                  type='text'
                  name='last_name'
                  value={last_name}
                  required
                  onChange={(e) => setLastName(e.target.value)}
                  className='text-sm sm:text-base placeholder-gray-400 px-[14px] py-[12px] rounded-lg border-1 border-[#E8E8E8] w-full  focus:outline-none focus:border-primary focus:border-1'
                  placeholder='Last Name '
                />
              </div>
            </div>

            <div className='flex flex-col mb-6'>
              <label
                for='email'
                className='mb-1 text-[16px] tracking-wide text-gray-600'
              >
                E-Mail:
              </label>
              <div className='relative'>
                <div className='inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400'></div>

                <input
                  type='email'
                  name='email'
                  value={email}
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  className='text-sm sm:text-base placeholder-gray-400 px-[14px] py-[12px] rounded-lg border-1 border-[#E8E8E8] w-full  focus:outline-none focus:border-primary focus:border-1'
                  placeholder='Email '
                />
              </div>
            </div>

            <div className='flex flex-col mb-6'>
              <label
                for='phone'
                className='mb-1 text-[16px] tracking-wide text-gray-600'
              >
                Phone Number
              </label>
              <div className='relative'>
                <div className='inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400'></div>

                <input
                  type='text'
                  name='phone_number'
                  value={phone_number}
                  required
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className='text-sm sm:text-base placeholder-gray-400 px-[14px] py-[12px] rounded-lg border-1 border-[#E8E8E8] w-full  focus:outline-none focus:border-primary focus:border-1'
                  placeholder='Phone No. '
                />
              </div>
            </div>

            <div className='flex flex-col mb-6'>
              <label
                for='phone'
                className='mb-1 text-[16px] tracking-wide text-gray-600'
              >
                Password
              </label>
              <div className='relative'>
                <div className='inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400'></div>

                <input
                  type='text'
                  name='password'
                  value={password}
                  required
                  onChange={(e) => setPassword(e.target.value)}
                  className='text-sm sm:text-base placeholder-gray-400 px-[14px] py-[12px] rounded-lg border-1 border-[#E8E8E8] w-full  focus:outline-none focus:border-primary focus:border-1'
                  placeholder='Set Password. '
                />
              </div>
              <small className='text-[#ff0000] text-sm italic'>Add password only if user is to be a Sub-Agent</small>
            </div>


            <div className='w-full text-center'>
              <button
                type='submit'
                className={`${
                  handleButtonDisable()
                    ? 'cursor-not-allowed opacity-75 pointer-events-none'
                    : 'opacity-100 '
                } text-[#fff] py-3 bg-primary    text-sm sm:text-base  rounded  w-2/5 `}
              >
                {loading ? <Spinner text='ADDING USER...' /> : 'SUBMIT'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddUserModal;
