import { createPortal } from 'react-dom';
import './Loader.css';

export default function ThreeDLoader({ active }) {
  return (
    <div className={`${active ? 'block' : 'hidden'}`}>
      {' '}
      {createPortal(
        <div className='fixed top-0 w-screen h-screen z-50 bg-[#344054B2] bg-opacity-[70%] flex items-center justify-center'>
          <div className='lds-spinner'>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>,
        document.body
      )}
    </div>
  );
}
