import React from 'react';

const TableSkeleton = () => {
  return (
    <div>
      <tr className='bg-white border-b w-full'>
        <td className='py-4 px-6'>
          <div className='w-[100px] h-[20px] bg-[#d1d5db] animate-pulse'></div>
        </td>
        <td className='py-4 px-6'>
          <div className='w-[150px] h-[20px] bg-[#d1d5db] animate-pulse'></div>
        </td>
        <td className='py-4 px-6'>
          <div className='w-[200px] h-[20px] bg-[#d1d5db] animate-pulse'></div>
        </td>
        <td className='py-4 px-6'>
          <div className='w-[100px] h-[20px] bg-[#d1d5db] animate-pulse'></div>
        </td>

        <td className='py-4 px-6'>
          <div className='w-[100px] h-[30px] bg-[#d1d5db] rounded-full animate-pulse'></div>
        </td>
        <td className='py-4 px-6'>
          <div className='w-[100px] h-[30px] bg-[#d1d5db] rounded-full animate-pulse'></div>
        </td>
        <td className='py-4 px-6'>
          <div className='w-[100px] h-[30px] bg-[#d1d5db] rounded-full animate-pulse'></div>
        </td>
  
      </tr>
    </div>
  );
};

export default TableSkeleton;
