import React, { useState, useEffect } from 'react';
import Navbar from '../../components/Navbar';
import Add from '../../assets/add.svg';
import Axios from 'axios';
import { baseURL } from '../../components/Global/api';
import AddUserModal from '../../components/AddUserModal';
import TableSkeleton from '../../components/TableSkeleton';
import { toast } from 'react-toastify';
import { ImCancelCircle } from 'react-icons/im';
import { Spinner } from '../../components/Loader';
let token = '';
const UserPage = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [ShowAddUserModal, setShowAddUserModal] = useState(false);
  const [ShowReset, setShowRest] = useState(false);
  const [subAgentLoading, setSubAgentLoading] = useState(false);

  const handleAddUserModal = () => {
    setShowAddUserModal(true);
  };

  const handleCloseUserModal = () => {
    setShowAddUserModal(false);
  };

    const [role, setRole] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const openModal = (userId) => {
    setSelectedId(userId);
    setModalOpen(true);
    console.log('Opening modal for user ID:', userId);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const addSubAgent = () => {
    setSubAgentLoading(true);
    if (localStorage.getItem('userToken')) {
      token = JSON.parse(localStorage.getItem('userToken'));
      const Data = {
        user_id: selectedId,
      };
      Axios.post(`${baseURL}agent/users/make-sub-agent`, Data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          if (res.data.success) {
            toast.success(res.data.message, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            });
          }
          setSubAgentLoading(false);
          closeModal();
          LoadUsersData();
          console.log('Response data', res.data);
        })
        .catch((err) => {
          setSubAgentLoading(false);
          if (err) {
            toast.error(err.message, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            });
          }
          console.log(err);
        });
    }
  };


  
  const LoadUsersData = (page = currentPage, query = searchQuery) => {
    setLoading(true);
    if (localStorage.getItem('userToken')) {
      token = JSON.parse(localStorage.getItem('userToken'));
      Axios.get(`${baseURL}agent/users?should_paginate=${page}`, {
        params: {
          search: query.toString().trim(),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          setData(res.data.data);
          setTotalPages(res.data.meta.last_page);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => {
        const newPage = prev - 1;
        LoadUsersData(newPage);
        return newPage;
      });
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => {
        const newPage = prev + 1;
        LoadUsersData(newPage);
        return newPage;
      });
    }
  };

  const handleSearch = () => {
    // Reset to the first page when performing a search

    setCurrentPage(1);
    LoadUsersData(1, searchQuery); // Load data for the first page with current search parameters
    setShowRest(true);
  };

  const resetSearch = () => {
    setSearchQuery('');
    setCurrentPage(1);
    LoadUsersData(1, '');
    setShowRest(false);
    setRole('');
  };

  useEffect(() => {
    LoadUsersData();
  }, []);

  return (
    <>
      <Navbar title='User Management' />
      <div className='lg:px-10 xl:px-8 py-6 lg:py-10 px-4'>
        <div className='welcome flex w-full'>
          <div className='ml-auto button space-x-3 flex item-center lg:justify-self-end'>
            <button
              onClick={handleAddUserModal}
              className='flex items-center text-[#fff] px-6 py-2 rounded-md space-x-3 bg-primary text-[16px]'
            >
              <img src={Add} alt='' srcset='' />
              <span>Add User</span>
            </button>
          </div>
        </div>
      </div>

      <div className='flex flex-wrap  mb-5'>
        <div className='w-full  lg:px-3 mb-6  mx-auto'>
          <div className='   flex flex-col    rounded-[.95rem] bg-[#fff] m-5'>
            <div className=' flex flex-col  border border-[#6D6D7A]/30  rounded-2xl  bg-[#fff]'>
              <div className='px-4 lg:px-9 pt-5 flex justify-between items-stretch flex-col lg:flex-row'>
                <div className='flex py-4 items-start lg:justify-center  font-medium text-[#000] text-[20px]'>
                  <span className='mr-3 text-dark'>All Registered Users</span>
                  <span>{data?.length}</span>
                </div>
                <div className='flex  items-center my-2 flex-col gap-2 lg:flex-row'>
                  <input
                    type='text'
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className='text-sm sm:text-base placeholder-[#797979] px-[14px] py-2 rounded-lg border-1 border-[#E8E8E8] w-full focus:outline-none focus:border-primary focus:border-1 '
                    placeholder='Search...'
                  />
                  <button
                    onClick={handleSearch}
                    className=' bg-primary text-[#fff] px-4 py-2 rounded-lg w-full '
                  >
                    Search
                  </button>

                  {ShowReset && (
                    <button
                      onClick={resetSearch}
                      className='ml-2 bg-[#980606] text-[white] px-4 py-2 rounded-lg w-full '
                    >
                      Reset
                    </button>
                  )}
                </div>
              </div>

              {loading ? (
                [...Array(4)].map((_, index) => <TableSkeleton key={index} />)
              ) : (
                <div className='flex-auto block py-8 pt-6 px-4 lg:px-9'>
                  <div className='overflow-x-auto'>
                    {data?.length > 0 ? (
                      <table className='w-full my-0 align-middle text-dark border-[#6D6D7A]'>
                        <thead className='align-bottom'>
                          <tr className=' text-[1rem] '>
                            <th className='py-4 px-6 text-start '>FirstName</th>
                            <th className='py-4 px-6 text-start'>LastName</th>
                            <th className='py-4 px-6 text-start '>Email</th>
                            <th className='py-4 px-6 text-start '>Phone</th>
                            <th className='py-4 px-6 text-start '>Role</th>
                            <th className='py-4 px-6 text-start'>Status</th>
                            <th className='py-4 px-6 text-start'>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {data?.map((user, i) => (
                            <tr
                              key={i}
                              className='border-b border-[#6D6D7A]/40 last:border-b-0 '
                            >
                              <td className='py-4 px-6'>
                                <div className=''>
                                  <span className='mb-1 text-md/normal  hover:text-primary'>
                                    {' '}
                                    {user?.first_name}
                                  </span>
                                </div>
                              </td>
                              <td className=' py-4 px-6'>
                                <span className=' text-md/normal'>
                                  {user?.last_name}
                                </span>
                              </td>
                              <td className='py-4 px-6'>
                                <span className=' text-md/normal'>
                                  {user?.email}
                                </span>
                              </td>

                              <td className='py-4 px-6'>
                                <span className=' text-md/normal'>
                                  {user?.phone_number}
                                </span>
                              </td>

                              <td className='py-4 px-6'>
                                <span className=' text-md/normal text-[#642E8E] bg-[#F6F6F8] px-4 py-2 rounded-full'>
                                  {user?.role?.charAt(0).toUpperCase() +
                                    user?.role?.substring(1).toLowerCase()}
                                </span>
                              </td>
                              <td className='py-4 px-6'>
                                <span
                                  className={`${
                                    user?.status === 'ENABLED'
                                      ? 'text-[green] bg-[green]/10'
                                      : 'text-[red] bg-[red]/10'
                                  } text-center align-baseline inline-flex px-4 py-2 mr-auto text-[.95rem] leading-none  rounded-full`}
                                >
                                  {user?.status === 'ENABLED'
                                    ? 'Enabled'
                                    : 'Failed'}
                                </span>
                              </td>
                              <td className='py-4 px-6'>

                                {user?.role === 'SUB_AGENT' && (
                                            <button
                                            onClick={() => openModal(user?.id)}
                                            className={`${user?.role === 'SUB_AGENT' ? 'cursor-not-allowed opacity-60' : ''} z-10 bg-primary text-[#FFF] rounded-md px-4 py-2 `}
                                            disabled={user?.role === 'SUB_AGENT'}
                                          >
                                            {user?.role === 'SUB_AGENT'
                                              ? 'Sub Agent'
                                              : 'Assign Sub Agent'}
                                          </button>
                                ) }
                    
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <div className='py-4 flex justify-center w-full text-[25px]'>
                        Sorry No result found!
                      </div>
                    )}

                    <div className='flex items-center py-4'>
                      {currentPage > 1 && (
                        <button
                          onClick={handlePrevPage}
                          className='bg-primary text-[#fff] px-4 py-2 rounded-lg'
                        >
                          Prev
                        </button>
                      )}

                      <span className='mx-2'>
                        Page {currentPage} of {totalPages}
                      </span>

                      {currentPage < totalPages && (
                        <button
                          onClick={handleNextPage}
                          className='bg-primary text-[#fff] px-4 py-2 rounded-lg'
                        >
                          Next
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {ShowAddUserModal && (
        <AddUserModal onClose={handleCloseUserModal} LoadData={LoadUsersData} />
      )}


{modalOpen && (

<div
className={`fixed inset-0 flex items-center m-[10px] lg:m-0 justify-center z-50`}
>
<div className='fixed inset-0 bg-[#000] opacity-50'></div>
<div className='relative bg-[#fff] rounded-lg w-[450px] flex flex-col gap-6'>
  <span className='ml-auto px-6 cursor-pointer py-4' onClick={closeModal}>
    <ImCancelCircle className='w-6 h-6 text-[#D22421]' />
  </span>

  <div className=' p-[40px]'>
    <form >

    <h2 className='text-center mb-3 text-[25px] font-bold '>
    Are you sure you want to convert User to Sub Agent?
              </h2>

  


            <div className='w-full mx-auto flex justify-center'>
            <button
          type='button'
          onClick={addSubAgent}
          className={`
           text-[#fff] py-3 bg-primary text-center   rounded w-1/2`}
        >
          {subAgentLoading ? <Spinner text='ADDING SUB-AGENT...' /> : 'ADD'}
        </button>

            </div>
             
          
 




   
    </form>
  </div>
</div>
</div>
      
      )}
    </>
  );
};

export default UserPage;
