import React from 'react';

import Sidebar from '../components/Sidebar';
import { Outlet } from 'react-router-dom';

const DashboardLayout = () => {
  return (
    <div>
      <div className='flex min-h-screen '>
        <aside className='w-72 hidden lg:flex bg-[#fff] h-screen top-0  sticky z-10 '>
          <Sidebar />
        </aside>

        <main className='flex-1 bg-[#EFF3F4] overflow-x-hidden min-h-screen'>
          
            <Outlet />
   
        </main>
      </div>
    </div>
  );
};

export default DashboardLayout;
