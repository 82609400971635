import React, { Suspense, lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import PublicRoutes from './services/PublicRoute';
import PrivateRoutes from './services/PrivateRoute';
import Loader from './components/Loader/Loader';
import UserPage from './pages/users/UserPage';
import Vendor from './pages/vendors/Vendor';

const Login = lazy(() => import('./pages/Auth/Login'));
const Register = lazy(() => import('./pages/Auth/Register'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const CoverList = lazy(() => import('./pages/coverlist'));
const CoverListDetails = lazy(() => import('./pages/coverlist/CoverListDetails'));
const AgInsurance = lazy(() => import('./pages/vendors/AgInsurance/AgInsurance'));
const IeiInsurance = lazy(() => import('./pages/vendors/IeI/IeI'));
const NoorTakaful = lazy(() => import('./pages/vendors/NoorTakaful/NoorTakaful'));
const CoverPagePrintAG = lazy(() => import('./pages/vendors/AgInsurance/Print'));
const CoverPagePrintIEI = lazy(() => import('./pages/vendors/IeI/Print'));
const UnitSale = lazy(() => import('./pages/SubAgent/UnitSale'));


function App() {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route element={<DashboardLayout />}>
            <Route path='/' element={<Navigate to='/dashboard' replace />} />
            <Route path='/dashboard' element={<Dashboard />} />

            <Route path='/users' element={<UserPage />} />
            <Route path='/coverlist' element={<CoverList />} />
            <Route path='/coverlist/:id' element={<CoverListDetails />} />
            <Route path='/vendors' element={<Vendor />} />
            <Route path='/vendors/aginsurance' element={<AgInsurance />} />
            <Route path='/vendors/iei' element={<IeiInsurance />} />
            <Route path='/vendors/noortakaful' element={<NoorTakaful />} />
            <Route path='/sub-agent' element={<UnitSale />} />
           
            
          </Route>

          <Route
                  path='/coverlist/print-ag/:id'
           
                 element={<CoverPagePrintAG/>}
                />

                <Route
                  path='/coverlist/print-iei/:id'
  
                  element={<CoverPagePrintIEI/>}
                />
        </Route>


        <Route element={<PublicRoutes />}>
          <Route path='auth/login' element={<Login />} />
          <Route path='auth/register' element={<Register />} />
        </Route>
      </Routes>
    </Suspense>
  );
}

export default App;
