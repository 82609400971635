import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import AgImage from '../../assets/cropped-ag.png';
import NoorImage from '../../assets/noortakaful.png';
import IeiImage from '../../assets/iei.png';
const Vendor = () => {
  return (
    <div class=''>
          <Navbar title='Vendors' />
          <div className='lg:px-10 xl:px-8 py-6 lg:py-10 px-4'>
          <div className='grid grid-cols-1 lg:grid-cols-3 gap-4'>
        <div class='bg-[#fff] pb-2 lg:pb-0 rounded-lg '>
          <div
            className='p-6 text-[#000] flex flex-col gap-4  '
     
          >
            <div className='w-full ml-auto pb-4'>
              <img
                src={AgImage}
                alt='AG insurance'
                style={{ width: '130px' }}
              />
            </div>
            <h3 className='text-[#000] text-[25px] font-bold pb-4'>AG Insurance</h3>
       
            <div>
            <Link to='/vendors/aginsurance' class=' bg-primary text-[#fff] p-3 rounded-md'>
              View Vendor
            </Link>
            </div>
          
          </div>
        </div>

        <div className='bg-[#fff] pb-2 lg:pb-0 rounded-lg '>
          <div
            className='p-6 text-[#000] flex flex-col gap-4 '
     
          >
            <div className='w-full ml-auto pb-4'>
            <img
                src={NoorImage}
                alt='Noor Takaful'
                style={{ width: '100px' }}
              />
            </div>
            <h3 className='text-[#000] text-[25px] font-bold pb-4'>Noor Takaful</h3>
       
            <div>
            <button disabled class=' bg-primary text-[#fff] p-3 rounded-md'>
              View Vendor
            </button>
            </div>
          
          </div>
        </div>

        <div className='bg-[#fff] pb-2 lg:pb-0 rounded-lg '>
          <div
            className='p-6 text-[#000] flex flex-col gap-4 '
     
          >
            <div className='w-full ml-auto pb-4'>
            <img
                src={IeiImage}
                alt='IEI insurance'
                style={{ width: '100px' }}
              />
            </div>
            <h3 className='text-[#000] text-[25px] font-bold pb-4'>IEI Insurance</h3>
       
            <div>
            <Link to='/vendors/iei' class=' bg-primary text-[#fff] p-3 rounded-md'>
              View Vendor
            </Link>
            </div>
          
          </div>
        </div>

      
      </div>
          </div>

    </div>
  );
};

export default Vendor;
