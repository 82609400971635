import React, { useState, useCallback } from 'react';

import Sidebar from './Sidebar';
import MenuOpen from '../assets/menu-open.svg';
import Profile from '../assets/profile.png';

const Navbar = ({ title }) => {
  const [toggle, setToggle] = useState(false);

  const handleToggle = useCallback(() => {
    setToggle(false);
  }, []);
  return (
    <>
      <div className='bg-[#fff]  lg:h-24 flex border-b border-[#6D6D7A]/30 items-end sticky top-0'>
        <div className='flex items-center justify-between w-full px-6 py-4 lg:pb-2 '>
          <h1 className='text-[px] lg:text-3xl font-medium'>{title}</h1>

          <div className='lg:block hidden'>
            <div class='profile border border-black w-10 shadow-full rounded-full'>
              <img
                class='w-10  border rounded-full '
                src={Profile}
                alt=''
                srcset=''
              />
            </div>
          </div>

          <div className='icon lg:hidden' onClick={() => setToggle(!toggle)}>
            <img className='w-10' src={MenuOpen} alt='' srcset='' />
          </div>
        </div>

        {toggle && (
          <div className='backdrop fixed w-full bg-[#000] bg-opacity-30 h-screen top-0 left-0 flex transition ease-in-out delay-150 '>
            <Sidebar handleClick={handleToggle} />
          </div>
        )}
      </div>
    </>
  );
};

export default Navbar;
